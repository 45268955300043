<template class="banner">
  <div class="mobile">
    <h2>MÁS DE 25 AÑOS</h2>
    <h2><b>INNOVANDO Y CRECIENDO</b></h2>
    <i class="line"></i>
  </div>
  <div class="banner-container"></div>
</template>

<script>
export default {
  name: "Electro3Home",
};
</script>

<style scoped>
.banner {
  justify-content: center;
  align-items: center;
}

.banner-container {
  margin: 0rem auto;
  width: 100%;
  height: 40rem; /* Ajusta la altura según tus necesidades */
  display: flex;
  justify-content: flex-start; /* Alinea el contenido hacia la izquierda */
  align-items: center;
  background-image: url("@/assets/home.png");
  background-size: contain; /* La imagen se ajustará al contenedor sin recortarse */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.mobile {
  display: none;
  margin: 3em 0 3em 0;
  color: #131337;
  font-family: Arial, sans-serif;
}

h2 {
  font-weight: 100;
}

.line {
    width: 330px;
    content: "";
    display: block;
    height: 1px;
    background: #131337;
    margin-top: 30px;
  }

@media (max-width: 1024px) {
  .banner-container {
    width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
    height: 20rem; /* Reduce la altura del banner en móviles */
    padding-left: 1rem; /* Reduce el padding en pantallas pequeñas */
    justify-content: center; /* Centra el contenido en pantallas móviles */
    background-image: url("@/assets/home.png");
    background-position: left; /* Muestra solo la parte derecha de la imagen */
    background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
    position: relative; /* Necesario para la capa oscura */
  }
}

@media (max-width: 960px) {
  .banner-container {
    background-image: url("@/assets/home_mobile.png");
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
