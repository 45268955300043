<template class="banner">
  <div class="banner-container"></div>
  <div class="mobile">
    <div class="header">
      <h2>NUESTROS <b>PARTNERS.</b></h2>
      <i class="line"></i>
      <p><I>MÁS DE 100 PROYECTOS PÚBLICOS Y PRIVADOS.</I></p>
    </div>

    <img
      src="@/assets/partners_mobile.png"
      alt="Nuestros Partners en Electro3"
    />
  </div>
</template>

<script>
export default {
  name: "NuestrosPartners",
};
</script>

<style scoped>
.banner {
  justify-content: center;
  align-items: center;
}

.banner-container {
  margin-top: 0%;
  width: 100%;
  height: 40rem; /* Ajusta la altura según tus necesidades */
  display: flex;
  justify-content: flex-start; /* Alinea el contenido hacia la izquierda */
  align-items: center;
  background-image: url("@/assets/partners.png");
  background-size: contain; /* La imagen se ajustará al contenedor sin recortarse */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.mobile {
  display: none;
  background-color: #e3333b;
  color: #fff;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  height: 40vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.line {
  width: 100%;
  content: "";
  display: block;
  height: 1px;
  background: #fff;
  margin-bottom: 1em;
}

img {
  display: none;
}

@media (max-width: 1024px) {
  .banner-container {
    width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
    height: 20rem; /* Reduce la altura del banner en móviles */
    padding-left: 1rem; /* Reduce el padding en pantallas pequeñas */
    justify-content: center; /* Centra el contenido en pantallas móviles */
    background-image: url("@/assets/partners.png");
    background-position: left; /* Muestra solo la parte derecha de la imagen */
    background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
    position: relative; /* Necesario para la capa oscura */
  }
}

@media (max-width: 960px) {
  .banner-container {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
