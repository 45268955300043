<template class="banner">
  <div class="banner-container"></div>
  <div class="mobile">
    <h2>NUESTROS</h2>
    <h2><b>SERVICIOS Y SOLUCIONES</b></h2>
    <i class="line"></i>
  </div>
  <img
    class="items"
    src="@/assets/ServiciosSoluciones_mobile.png"
    alt="Nuestras soluciones en Electro3"
  />

  <div class="items-mobile">
    <img src="@/assets/ServiciosSoluciones_bannerTop.png" alt="Banner">
    <div style="background-color: #0c74b4;">
      <h3>Redes y Seguridad</h3>
      <p>Instalamos grandes redes de video vigilancia, tendidos de fibra óptica, mantenimiento de centros operativos y desarrollo de nuevos proyectos.</p>
    </div>

    <div style="background-color: #28a9c1;">
      <h3>Consultoría IT</h3>
      <p>Damos respuestas inmediatas a las demandas de las empresas en cuanto a conectividad, almacenamiento, espacios de trabajo y automatización de procesos.</p>
    </div>

    <div style="background-color: #13a19b;">
      <h3>Software y Desarrollo</h3>
      <p>Desarrollamos y creamos el software que su emprendimiento o empresa necesita según sus necesidades, logrando productos de gran calidad y con un soporte técnico dedicado.</p>
    </div>

    <div style="background-color: #2cac64;">
      <h3>Capacitación y Reciclado</h3>
      <p>Concientes con el cuidado del ambiente, creamos una unidad de negocio que realiza procesos de tratamiento, compactación y reciclado de grandes lotes de vehículos y residuos metalíferos y secos.</p>
    </div>

    <div style="background-color: #046434;">
      <h3>Mayorista de Insumos</h3>
      <p>Proveemos de una amplia variedad de insumos para empresas que van desde repuestos para el área automotriz hasta productos tecnológicos.</p>
    </div>
    <img src="@/assets/ServiciosSoluciones_bannerBottom.png" alt="Banner">
  </div>

</template>

<script>
export default {
  name: "NuestrosServicios",
};
</script>

<style scoped>
.banner {
  justify-content: center;
  align-items: center;
}

.banner-container {
  margin-top: 0%;
  width: 100%;
  height: 40rem; /* Ajusta la altura según tus necesidades */
  display: flex;
  justify-content: flex-start; /* Alinea el contenido hacia la izquierda */
  align-items: center;
  background-image: url("@/assets/ServiciosSoluciones.png");
  background-size: contain; /* La imagen se ajustará al contenedor sin recortarse */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.mobile {
  display: none;
  height: 40vh;
  background-color: #29235c;
  color: #fff;
  font-family: Arial, sans-serif;
  padding-bottom: 1em;
}

h2 {
  font-weight: 100;
  margin: 0.1em;
}

.line {
  width: 350px;
  content: "";
  display: block;
  height: 1px;
  background: #fff;
  margin-top: 0.5em;
}

.items {
  display: none;
}

.items-mobile {
  display: none;
  color: #fff;
}

h3 {
  padding: 2em 2em 0 2em;
}

p {
  padding: 0 2em 2em 2em;
}

img {
  width: 100%;
}

@media (max-width: 1024px) {
  .banner-container {
    width: 100%; /* Ocupa el 100% del ancho en pantallas pequeñas */
    height: 20rem; /* Reduce la altura del banner en móviles */
    padding-left: 1rem; /* Reduce el padding en pantallas pequeñas */
    justify-content: center; /* Centra el contenido en pantallas móviles */
    background-image: url("@/assets/ServiciosSoluciones.png");
    background-position: left; /* Muestra solo la parte derecha de la imagen */
    background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
    position: relative; /* Necesario para la capa oscura */
  }
}

@media (max-width: 960px) {
  .banner-container {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .items {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 768px) {
  .items {
    display: none;
  }

  .items-mobile {
  display: flex;
  flex-direction: column;
}

}
</style>
