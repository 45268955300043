<template> 
  <router-view />
</template>

<script>
export default {
  watch: {
    '$route'(to) {
      this.updateFaviconAndTitle(to.path);
    }
  },
  mounted() {
    this.updateFaviconAndTitle(this.$route.path);
  },
  methods: {
    updateFaviconAndTitle(path) {
      const faviconLink = document.querySelector("link[rel='icon']") || document.createElement('link');
      faviconLink.rel = 'icon';

      // Usa rutas relativas a la carpeta public
      if (path.includes('/varta')) {
        faviconLink.href = '/Varta-new-icon.ico'; // Ruta relativa desde /public
        document.title = 'Varta';  // Cambia el título a 'Varta'
      } else {
        faviconLink.href = '/electro3-icon.ico'; // Ruta relativa desde /public
        document.title = 'Electro3';  // Cambia el título a 'Electro3'
      }
      
      // Si el link de icono no existe, lo añade al head
      if (!document.querySelector("link[rel='icon']")) {
        document.head.appendChild(faviconLink);
      }
    }
  }
};
</script>

<style>

body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
  min-height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
