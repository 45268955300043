<template>
  <div class="container">
    <!-- Menú -->
    <Electro3Menu />
    <!-- <VartaCarousel /> -->
    <div v-if="isParentRoute">
      <!-- Imagen para poner texto-->
      <Electro3Home />
      <!-- Sección de características -->
      <!-- <VartaFeatures /> -->
    </div>

    <router-view></router-view>
    <!-- <VartaFooter/>  -->
    <!-- Varta Silver -->
  </div>
  <Electro3Footer />
</template>

<script>
import Electro3Menu from "@/components/Electro3Menu.vue";
import Electro3Home from "@/components/Electro3Home.vue";
import Electro3Footer from "./Electro3Footer.vue";

export default {
  components: {
    Electro3Menu,
    Electro3Home,
    Electro3Footer,
  },
  data() {
    return {};
  },
  computed: {
    isParentRoute() {
      const route = this.$route;
      // Verifica si estás en la ruta '/' y no en sus hijas
      return route.path === '/' // se hizo asi por que en mozilla muestra la primera opcion y en crhom la segunda
    }
  }
};
</script>

<style>
body {
  background-color: #f1f1f1; /* Se quita el scoped para que todos los componentes de E3 tengan fondo gris */
}
</style>
