<template>
  <div class="footer">
    <p><b>Electro3 SAS - 9 de Julio 1002 Este - San Juan - Argentina</b></p>
    <p>264 4200897</p>
    <p>info@electro3.com.ar</p>
  </div>
</template>

<script setup></script>

<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1em 7em 5em 0;
  background-color: #f1f1f1;
}

p {
    font-style: italic;
    font-size: .9em;
    margin: 0.2em;
    color: #5e5e5e;
}

@media (max-width: 768px) {
  .footer {
    padding: 1em 4em 5em 0;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 2em 0 3em 0;
    align-items: center;
  }
}

</style>
