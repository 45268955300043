<template class="banner">
  <div class="banner-container"></div>
  <div class="mobile">
    <p>
      Con más de 25 años de experiencia, en <b><I>Electro3</I></b
      >, hemos crecido constantemente para convertinos en una
      <b><I>empresa versátil y líder</I></b> en cada uno de los sectores en los
      que operamos.
    </p>

    <p>
      <b
        ><I
          >Nuestro compromiso no solo está en el crecimiento regional, sino
          también con la protección del medio ambiente.</I
        ></b
      >
    </p>

    <p>
      Optimizamos recursos y provemos activamente el reciclaje y la recuperación
      de materiales, buscando siempre
      <b><I>soluciones sostenibles para un futuro más verde y eficiente.</I></b>
    </p>
  </div>
</template>

<script>
export default {
  name: "SobreNosotros",
};
</script>

<style scoped>
.banner {
  justify-content: center;
  align-items: center;
}

.banner-container {
  margin-top: 0%;
  width: 100%;
  height: 40rem; /* Ajusta la altura según tus necesidades */
  display: flex;
  justify-content: flex-start; /* Alinea el contenido hacia la izquierda */
  align-items: center;
  background-image: url("@/assets/sobreNosotros.png");
  background-size: contain; /* La imagen se ajustará al contenedor sin recortarse */
  background-position: center; /* Centra la imagen en el contenedor */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.mobile {
  display: none;
  height: 90vh;
  padding: 2em;
}

p {
  color: #fff;
  font-family: Arial, sans-serif;
}

@media (max-width: 1024px) {
  .banner-container {
    height: 20rem; /* Reduce la altura del banner en móviles */
    padding-left: 1rem; /* Reduce el padding en pantallas pequeñas */
    justify-content: center; /* Centra el contenido en pantallas móviles */
    background-image: url("@/assets/sobreNosotros.png");
    position: relative; /* Necesario para la capa oscura */
  }
}

@media (max-width: 960px) {
  .banner-container {
    background-image: url("@/assets/sobreNosotros_mobile_1.png");
    background-size: cover;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-image: url("@/assets/sobreNosotros_mobile_2.png");
    background-position: left; /* Muestra solo la parte derecha de la imagen */
    background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
  }
}
</style>
